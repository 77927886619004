<template>
  <div class="home" id="test">
    <div class="stage-bg"></div>
    <div class="border">
      <div class="stage">
        <h2>Rückantwort</h2>

        <h1 class="h1">Lisa & Thomas</h1>
      </div>
      <v-container>
        <v-row align-content="center">
          <v-col cols="12">
            <v-main>
              <slot></slot>
            </v-main>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <footer>
      <p>
        <strong>&copy; 2021 lisa-und-tommy.de</strong> - all rights reserved!
        <br /><span class="small"
          >Konzept, Design und Entwicklung -
          <a href="https://bymarc.de">bymarc.media</a>
        </span>
      </p>
      <p class="links">
        <a href="Impressum">Impressum</a> |
        <a href="Datenschutz">Datenschutz</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Theme",

  data() {
    return {};
  },
  computed: {
    code() {
      //console.log("code1:", this.$store.getters.getCode);
      return this.$store.getters.getCode;
    },
  },
  components: {},
};
</script>
<style lang="scss">
// @import url("../styles/global.scss");
</style>
